import React from "react";

function work() {
  return (
    <div className="bg-blue-100 w-full h-screen">
      <div className="sm:px-2 md:px-6 lg:px-64">
        <h1 className="text-2xl text-bold text-gray-900">Current Work</h1>
        <div class="mx-auto lg:mx-0 border-blue-500 border-b-2 opacity-25" />
        <ul className="list-disc list-inside">
          <li>
            <a
              href="https://integralapp.co"
              className="underline"
              target="_blank"
              rel="noreferrer"
            >
              Integral
            </a>
            : Cofounder
          </li>
        </ul>
        <h1 className="text-2xl text-bold text-gray-900">Previous Work</h1>
        <div class="mx-auto lg:mx-0 border-blue-500 border-b-2 opacity-25" />
        <ul className="list-disc list-inside">
          <li>
            <a
              href="https://hebbia.ai"
              className="underline"
              target="_blank"
              rel="noreferrer"
            >
              Hebbia
            </a>
            : Software Engineer
          </li>
          <li>
            <a
              href="https://snackpass.co"
              className="underline"
              target="_blank"
              rel="noreferrer"
            >
              Snackpass
            </a>
            : Software Engineer
          </li>
          <li>
            <a
              href="https://superworldapp.com"
              className="underline"
              target="_blank"
              rel="noreferrer"
            >
              Superworld
            </a>
            : Ethereum/Solidity Developer
          </li>
          <li>
            <a
              href="https://robojuice.ai"
              className="underline"
              target="_blank"
              rel="noreferrer"
            >
              RoboJuice
            </a>
            : Software Developer
          </li>
          <li>
            <a
              href="https://coachandcrew.com"
              className="underline"
              target="_blank"
              rel="noreferrer"
            >
              Coach & Crew
            </a>
            : Software Engineer
          </li>
        </ul>
      </div>
      <div className="h-screen w-screen"></div>
    </div>
  );
}

export default work;
