import { Switch, Route, BrowserRouter as Router } from "react-router-dom";

import Navbar from "./components/navbar.js"

import Home from "./pages/home"
import About from "./pages/about"
import Contact from "./pages/contact"
import Posts from "./pages/posts"
import Work from "./pages/work"
import Plan from "./pages/plan"
import Error from "./pages/error"

//Posts
import Books from "./pages/posts/books"
import Thinking from "./pages/posts/thinking"
import Screen from "./pages/posts/screen"

function App() {
  return (
    <Router>
      <Navbar/>
      <Switch>
        <Route path="/about" component={About}/>
        <Route path="/work" component={Work}/>
        <Route path="/posts/books" component={Books}/>
        <Route path="/posts/thinking" component={Thinking}/>
        <Route path="/posts/screentime" component={Screen}/>
        <Route path="/posts" component={Posts}/>
        <Route path="/contact" component={Contact}/>
        <Route path="/plan" component={Plan}/>
        <Route path="/error" component={Error}/>
        <Route path="/" component={Home}/>
      </Switch>
    </Router>
  );
}

/*Icons: https://heroicons.dev/ */

export default App;
