import React from "react";
import { TwitterTweetEmbed } from "react-twitter-embed";
export default function Screen() {
  return (
    <div className="bg-white w-full h-screen sm:px-2 md:px-6 lg:px-64">
      <div className="mx-auto">
        <TwitterTweetEmbed tweetId={"1346520406778212352"} />
      </div>
      <p>
        I'm so guilty of this, where I'll be stuck to my laptop, or phone or
        something. Whenever I give myself time to think or space from letting
        the devices like my laptop or my phones or anything really that take
        away my attention, it becomes unbelievable how free I feel. You just get
        new thoughts, feel more creative, and really question things more.
      </p>
      <br />
      <p>
        The few things I like to do to become a really critical and yet
        intriguing thinker definitely stems from being able to{" "}
        <b>ASK QUESTIONS (A LOT OF THEM) and LET YOUR MIND WANDER</b>. I think
        these two things make it especially important when trying to have an
        ultimately better life in every aspect. Clear head, clear thinking and
        understanding, more time to yourself (something we all need). Definitely
        recommend taking some time alone with absolutely no distractions.
        Meditation is a form of this as well.
      </p>
      <br />
      <p>
        <a href="https://breakingsmart.substack.com/p/against-waldenponding">
          {" "}
          Here{" "}
        </a>{" "}
        is just another article on the same topic, and shout out to Ashley on
        twitter for the great tweet. Gave me my daily chuckle.
      </p>
    </div>
  );
}
