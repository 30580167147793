import React from "react";
import { Link } from "react-router-dom";

export default function Thinking() {
  return (
    <div className="bg-blue-100 w-full h-full">
      <div className="sm:px-2 md:px-6 lg:px-64">
        <h1 className="text-2xl text-bold underline">
          Foundational Thinking: Building things up from its base
        </h1>
        <i className="text-sm">
          S/o to{" "}
          <a
            href="https://twitter.com/garrytan"
            target="_blank"
            className="underline text-blue-400"
          >
            Garry Tan
          </a>{" "}
          for introducing the concept to me.
        </i>
        <p className="">
          So I think this is a pretty important topic, and it was only recently
          I kind of started to think about something like this. Ever since I've
          gained a very solid understanding of math (more specifically
          calculus), I wanted to know why I suddenly got such a good feel for
          math. Math in general wasn't a very difficult subject for me, but it
          was more so that math wasn't a no study type of class for me. After
          learning calc, and understanding algebra fully (and I passed algebra
          before), I realized it was the . Ironically, I arrived at the root of
          the solution at being good at calculus using first principles thinking
          too, even before I realized that was what made the difference.
        </p>
        <br />
        <p>
          Foundational thinking is breaking down anything to its simplest
          "truths"/parts, and then building it up to different solutions, or to
          gain a deeper understanding. Generally this understanding helps to
          figure out problems and solutions that many others don't see, and
          leads you to do things like building a business that "no one has
          thought of". This concept dates back to Aristotle's time, where his
          followers would describe concepts like this. Physics is actually a
          great subject that reinforces this well, by pushing to think by first
          principles as opposed to by analogy. Analogical thinking is defined
          more as And analogical thinking, is the opposite of thinking by first
          principles. Thinking by analogy is trying to relate two or more things
          being similar in some aspects, but then assumed to be similar in other
          aspects as well. A pretty good example of this the companies who try
          to be the "Uber for _____"(fill in the blank there) or the "AirBNB for
          ______". Obviously, they model their product and business model off of
          entirely what those companies do, strictly modeling a lot of what
          works for them, which doesn't completely align with what they may
          need. This tweet by Naval, and a section of his book that I highly
          recommend reading, has a lot of great content (check it out{" "}
          <Link to="/posts/books">here</Link>)
        </p>
        <img
          className="h-64 w-64"
          src="https://images.squarespace-cdn.com/content/v1/5d23ae6c3b89290001fe3cdb/1599423524931-MKSE05V79IW0B5B95QSD/ke17ZwdGBToddI8pDm48kEqn_tE-nOCyd_D92OdQSCJ7gQa3H78H3Y0txjaiv_0fDoOvxcdMmMKkDsyUqMSsMWxHk725yiiHCCLfrh8O1z5QHyNOqBUUEtDDsRWrJLTmru5j-AQBePNiDV9hZLaF5Dm3CIRnt1bVvGpxT9ImXeSlPKLbptqvx-l9OAFOsoYg/Optimist_Contrarian%401x.jpg?format=1500w"
          alt="First Principles"
        />
        <p>
          First principles, in an overview touches on being able to reason
          upwards. Sal Khan from Khan Academy has a great Ted talk on why school
          shouldn't be on size fits all, and the thought process is really just
          this: a student ends up getting an 80 or a 90 on a test. Usually, that
          is known to be a good score and teachers, parents, students would all
          be very happy with scores like this. However, it misses out the fact
          that 10% of the material covered, wasn't even
        </p>
        <p>Completing this later</p>
      </div>
    </div>
  );
}
