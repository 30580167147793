import React from "react";

function About() {
  return (
    <div className="bg-blue-100 w-screen h-screen 2xl:h-screen">
      <div className="inline-block sm:px-2 md:px-5 lg:px-64 pt-4">
        <div className="px-2">
          <b>Background:</b>
          <p className="px-2 pt-2 leading-relaxed">
            Earlier on, I worked a lot in software and engineering. I got super
            interested in full stack web development concentrating mainly on
            backend. I really enjoyed working on projects for fun, especially
            ones that could solve some basic problems that I face everyday.
            After some time doing CS research, my interests in startups and tech
            entrepreneurship quickly arose. I liked the concept of a strong
            culture and working in extreme uncertainty to make something out of
            nothing. After this realization, I started doing work for some
            startups and after some up and down experiences, I wanted to embark
            on my own entrepreneurial journey. I got immersed into the process
            and it's been exciting. That's where my interests lead me today,
            until I reach my next "interest" pivot. Less important facts:
          </p>
          <ul className="list-disc list-inside bg-rose-200 px-2">
            <li>Graduated from North Brunswick Township High School in 2021</li>
            <li>Based out of Princeton, NJ</li>
          </ul>
          <div class="mx-auto lg:mx-0 border-blue-500 border-b-2 opacity-75" />
          <b className="pt-6">Interests:</b>
          <p className="pt-2 leading-relaxed px-2">
            These days I've been looking to work on a lot of startups. Here are
            some startup sectors I'm focused on:
            <ul className="list-disc list-inside">
              <li>Public APIs</li>
              <li>B2B (startup to startup especially)</li>
              <li>Internet Companies</li>
              <li>Marketplaces</li>
              <li>
                Social Consumer Experience (Involving a social aspect to a
                product)
              </li>
            </ul>
          </p>
          <div class="mx-auto lg:mx-0 border-blue-500 border-b-2 opacity-75" />
          <b className="pt-6">The "Why":</b>
          <p className="pt-2 leading-relaxed px-2">
            Towards the beginning, I was solely developing software and creating
            various personal projects. After a while, I thought of actually
            being the principal behind a startup movement, and I wanted to
            experience some of these challenges: extreme risk and uncertainty,
            fear of failure, and the one I found very appealing: no one ever has
            the answers/formula. With many jobs having "senior" or
            "more-experienced" positions, it goes with the fact that previous
            entrepreneurs can help with mitigating risk or getting you closer to
            becoming profitable, but no one is ever right. No one can tell you
            what to do, yet you can still be extremely "successful". There are
            no guarantees, and because of this, entrepreneurship has the highest
            ROI and highest reward so far in society . Being able to wake up
            every morning and have no idea what I'll be doing today, but knowing
            I'll be working towards missions/values I believe is, in my eyes,
            one of the most fulfilling and promising work to do.
          </p>
        </div>
      </div>
    </div>
  );
}

export default About;
