import React from "react";

function Error() {
  return (
    <div className="w-full h-screen bg-white">
      <h1 className="mx-auto text-2xl text-bold">No such page exists...</h1>
    </div>
  );
}

export default Error;
