import React from "react";
import { Link } from "react-router-dom";
import App from "./background.css";

function Posts() {
  return (
    <div className="bg-blue-100 w-full h-screen">
      <Link to="/posts/screentime">
        <div className="pt-4 shadow-lg sm:mx-auto md:mx-auto max-w-full sm:max-w-full md:max-w-5xl relative overflow-hidden rounded-md">
          <div className="bg-blue-100 p-2">
            <h2 className="text-2xl text-gray-700 font-bold">
              Getting Away from a Screen (2/26/21)
            </h2>
            <div className="flex mt-2 text-tiny sm:text-xs">
              <div className="">
                <div className="bg-gray-800 rounded-full">
                  <p className="text-orange-500 font-bold py-1 px-2">Article</p>
                </div>
              </div>
              <div className="pl-1">
                <div style={App} className="bg-gray-800 rounded-full">
                  <p className="text-orange-500 font-bold py-1 px-2">
                    Betterment
                  </p>
                </div>
              </div>
            </div>
            <p className="text-gray-500 mt-2">
              A small article based on a tweet that resonated with me
            </p>
          </div>
        </div>
      </Link>
      <Link to="/posts/thinking">
        <div className="pt-4 shadow-lg sm:mx-auto md:mx-auto max-w-full sm:max-w-full md:max-w-5xl relative overflow-hidden rounded-md">
          <div className="bg-blue-100 p-2">
            <h2 className="text-2xl text-gray-700 font-bold">
              Foundational Thinking (First Principles) 1/24/21
            </h2>
            <div className="flex mt-2 text-tiny sm:text-xs">
              <div className="">
                <div className="bg-gray-800 rounded-full">
                  <p className="text-orange-500 font-bold py-1 px-2">Article</p>
                </div>
              </div>
              <div className="pl-1">
                <div style={App} className="bg-gray-800 rounded-full">
                  <p className="text-orange-500 font-bold py-1 px-2">
                    Strategy
                  </p>
                </div>
              </div>
              <div className="pl-1">
                <div style={App} className="bg-gray-800 rounded-full">
                  <p className="text-orange-500 font-bold py-1 px-2">
                    Learning
                  </p>
                </div>
              </div>
            </div>
            <p className="text-gray-500 mt-2">
              An intro on a thinking process that has allowed me to develop some
              new ideas
            </p>
          </div>
        </div>
      </Link>
      <Link to="/posts/books">
        <div className="pt-4 shadow-lg sm:mx-auto md:mx-auto max-w-full sm:max-w-full md:max-w-5xl relative overflow-hidden rounded-md">
          <div className="bg-blue-100 p-2">
            <h2 className="text-2xl text-gray-700 font-bold">Books</h2>
            <div className="flex mt-2 text-tiny sm:text-xs">
              <div className="">
                <div className="bg-gray-800 rounded-full">
                  <p className="text-orange-500 font-bold py-1 px-2">Reading</p>
                </div>
              </div>
              <div className="pl-1">
                <div style={App} className="bg-gray-800 rounded-full">
                  <p className="text-orange-500 font-bold py-1 px-2">
                    Personal Development
                  </p>
                </div>
              </div>
            </div>
            <p className="text-gray-500 mt-2">
              A frequently updated list of books I've read, I'm reading, or I
              will/want to read
            </p>
          </div>
        </div>
      </Link>
      <div className="text-center pt-4 text-2xl text-blue-800">
        More posts coming soon
      </div>
    </div>
  );
}
export default Posts;
