import React from "react";

/* eslint-disable */

export default function Plan() {
  return (
    <iframe
      className="mx-auto"
      style={{ border: "1px solid rgba(0, 0, 0, 0.1)" }}
      width="800"
      height="450"
      src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2Fiy3ugomww422sTQ45UW0R9%2FUntitled%3Fnode-id%3D0%253A1"
      allowfullscreen
    ></iframe>
  );
}
