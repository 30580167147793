import {Link} from 'react-router-dom'
import React from 'react';

function Navbar() {
    const [navbarOpen, setNavbarOpen] = React.useState(false);
    return(
      <header className="" style={require("../pages/background.css")}>
        <div className="flex justify-between items-center px-4 py-3">
          <div className="">
            {/* <img className="h-8" src={logo} alt="Shrey Jain"/> */}
            <Link to="/" className="h-8 font-thin rounded items-center transition duration-500 ease-in-out text-gray-900 hover:text-blue-300">
              Shrey Jain
            </Link>
          </div>
          <div className="hidden px-2 sm:inline">
            <Link className=" py-1 px-2 font-semibold transition duration-500 ease-in-out hover:bg-gray-500 rounded" to="/about">About</Link>
            <Link className="my-1 py-1 px-2 font-semibold transition duration-500 ease-in-out hover:bg-gray-500 rounded" to="/work">Current Work</Link>
            <Link className="my-1 py-1 px-2 font-semibold transition duration-500 ease-in-out hover:bg-gray-500 rounded" to="/posts">Posts</Link>
            <Link className="my-1 py-1 px-2 font-semibold transition duration-500 ease-in-out hover:bg-gray-500 rounded " to="/contact">Info/Contact</Link>
          </div>
          <div className="sm:hidden">
            <button onClick={() => setNavbarOpen(!navbarOpen)} type="button" class="block text-gray-500 hover:text-white focus:text-white focus:outline-none">
              <svg class="h-6 w-6 fill-current" viewBox="0 0 24 24">
                <path fill-rule="evenodd" d={!navbarOpen ? "M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z" : "M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"}/>
                {/* <path fill-rule="evenodd" d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"/>) */}
              </svg>
            </button>
          </div>
        </div>
        <div className={
              "px-2 pt-2 pb-4 sm:hidden" +
              (navbarOpen ? "block transition duration-500 ease-in-out" : " hidden")
            }>
          <Link to="/about" className="transition duration-500 ease-in-out py-1 px-2 font-semibold hover:bg-gray-500 rounded block">About</Link>
          <Link className="my-1 transition duration-500 ease-in-out py-1 px-2 font-semibold hover:bg-gray-500 rounded block" to="/work">Current Work</Link>
          <Link className="my-1 transition duration-500 ease-in-out py-1 px-2 font-semibold hover:bg-gray-500 rounded block" to="/posts">Posts</Link>
          <Link className="my-1 transition duration-500 ease-in-out py-1 px-2 font-semibold hover:bg-gray-500 rounded block" to="/contact">Info/Contact</Link>
        </div>
      </header>
    )
}

export default Navbar