import { React } from "react";
import { Link } from "react-router-dom";
import Typical from "react-typical";
import "./background.css";

function Home() {
  return (
    <div
      style={require("./background.css")}
      className="w-full h-screen mx-auto"
    >
      <div className="sm:px-2 md:px-5 lg:px-64">
        <Typical
          steps={["Shrey Jain", 10000]}
          loop={Infinity}
          wrapper="p"
          className="text-5xl text-center"
        />
        <p className="py-1 text-center">
          Here is a personal website just about me and contact information.
          Enjoy and don't hesitate to reach out, especially if you're working on
          something cool.
        </p>
        <ul className="text-left pt-2 sm:px-4 md:px-1 text-gray-500">
          <li>
            <p className="text-lg py-2">
              <Link
                to="/about"
                className="bg-gradient-to-r from-teal-300 to-blue-300 rounded text-black p-1"
              >
                /about
              </Link>{" "}
              - Get a description of who I am, what I do, and why I do what I
              do.
            </p>
          </li>
          <li>
            <p className="text-lg py-2">
              <Link
                to="/work"
                className="bg-gradient-to-r from-teal-300 to-blue-300 rounded text-black p-1"
              >
                /work
              </Link>{" "}
              - Learn more about the work I'm currently doing and what I'm
              interested in working on.
            </p>
          </li>
          <li>
            <p className="text-lg py-2">
              <Link
                to="/posts"
                className="bg-gradient-to-r from-teal-300 to-blue-300 rounded text-black p-1"
              >
                /posts
              </Link>{" "}
              - Literally just a collection of random thoughts.
            </p>
          </li>
          <li>
            <p className="text-lg py-1">
              <Link
                to="/contact"
                className="bg-gradient-to-r from-teal-300 to-blue-300 rounded text-black p-1"
              >
                /contact
              </Link>{" "}
              - Where to reach out and contact me. Always interested in talking
              to new people, and especially hearing about new ideas.
            </p>
          </li>
        </ul>
      </div>
    </div>
  );
}
export default Home;
