import React from "react";

function books() {
  return (
    <div className="bg-blue-100 w-full h-screen sm:px-2 md:px-6 lg:px-64">
      <h1 className="text-2xl text-gray-800">Books</h1>
      <div class="mx-auto lg:mx-0 border-blue-500 border-b-2 opacity-25" />
      <ul className="list-disc list-inside bg-rose-200 px-2">
        <li className="underline">
          <a href="https://www.amazon.com/Zero-One-Notes-Startups-Future/dp/0804139296">
            0 to 1 by Peter Thiel
          </a>
        </li>
        <li className="underline">
          <a href="https://www.amazon.com/Street-Smart-Kids-Common-Sense/dp/1470159465">
            Street Smarts for Kids by Gordon Myers
          </a>
        </li>
        <li className="underline">
          <a href="http://theleanstartup.com/">
            The Lean Startup by Eric Ries{" "}
          </a>
        </li>
        <li className="underline">
          <a href="https://www.navalmanack.com/">
            Navalmanack by Eric Jorgenson
          </a>
        </li>
        <li className="underline">
          <a href="https://www.amazon.com/Hard-Thing-About-Things-Building/dp/0062273205">
            The Hard Things About Hard Things by Ben Horowitz{" "}
          </a>
        </li>
        <li className="underline">
          <a href="https://www.amazon.com/Six-Easy-Pieces-Essentials-Explained/dp/0465025277">
            Six Easy Pieces by Richard Feynman{" "}
          </a>
        </li>
        <li className="underline">
          <a href="">
            Antifragile: Things That Gain From Disorder by Nicholas Nassim Taleb
          </a>
        </li>
        {/* <li className="underline"><a href=""></a></li> */}
        {/* <li className="underline"><a href=""></a></li> */}
      </ul>
      <h1 className="text-2xl text-gray-800">To Read Next</h1>
      <div class="mx-auto lg:mx-0 border-blue-500 border-b-2 opacity-25" />
      <ul className="list-disc list-inside bg-rose-200 px-2">
        '
        <li className="underline">
          {" "}
          <a href=""> Better Never to Have Been By David Benatar </a>
        </li>
        <li className="">Sapiens by Yuval Noah Harari</li>
        <li className="">
          The Evolution of Everything: How New Ideas Emerge by Matt Ridley
        </li>
        <li className="">
          Thing Explainer: Complicated Stuff in Simple Words by Randall Munroe
        </li>
        <li className="">The Untethered Soul by Michael Singer</li>
        <li className="">The Compleat Strategyst by J.D. Williams</li>
        <li className="">The Evolution of Cooperation by Robert Axelrod</li>
        {/* <li className="underline"><a href=""></a></li> */}
      </ul>
      <p className="text-gray-800 text-bold">
        Always adding to this list, so if you have any recommendations, please
        send them to me through contact
      </p>
    </div>
  );
}
export default books;
